import React from "react"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import UspBand from "../components/uspBand/uspBand"

const WhoWeArePage = ({ data: { page, whoWeAre2, whoWeAre3, whoWeAre4, whoWeAre5, whoWeAre6, whoWeAre7 }, location }) => {
  return (
    <>
      <SEO
        titleOverride={"Who We Are"}
        descriptionOverride={"We’re a results-driven design, marketing and brand agency providing digital transformation for our clients."}
        pathnameOverride={location.pathname}
      />

      <section className="mb-6 overflow-hidden lg:mb-20">
        <div className="container">
          <div className="flex flex-wrap items-center">
            <div className="w-full pr-6 mb-12 lg:w-5/12 content lg:mb-0 lg:pl-12 xl:pl-24 xl:pr-12">
              <div>
                <h1>{page.title}</h1>
                <div className="content content--reset" dangerouslySetInnerHTML={{ __html: page.introduction }}></div>
              </div>
            </div>

            <div className="w-full lg:w-1/2 lg:ml-auto">
              {/* <Instagram images={insta.edges} /> */}
              <div className="flex flex-wrap -mx-2 md:-mx-4 xl:-mx-6 insta">
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ whoWeAre2.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ whoWeAre3.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ whoWeAre4.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ whoWeAre5.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ whoWeAre6.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ whoWeAre7.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>      
      
      <UspBand
        usps={page.usps}
        largeHeadings={true}
      />

      <section className="pb-12 mb-8 overflow-hidden border-b md:mb-16 lg:mb-24 md:pb-20 lg:pb-24 border-grey">
        <div className="container">
          <div className="w-full mx-auto md:w-10/12">
            <h2 className="text-center">Results from our 2024 Employee Survey</h2>
            <div className="flex flex-wrap md:-mx-6">
              {page.stats.map(({ heading, text }, index) => {
                return(
                  <div className="w-full mb-6 text-center md:w-1/3 md:mb-0 md:px-6" key={index}>
                    <span className="flex items-center justify-center w-40 h-40 mx-auto mb-6 text-5xl text-white rounded-full font-display bg-secondary-dark">{ heading }</span>
                    <span className="block text-xl xl:text-2xl lg:px-6 xl:px-8 2xl:px-10" dangerouslySetInnerHTML={{ __html: text }}></span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="mb-16 overflow-hidden md:mb-24 lg:mb-32 xl:mb-48">
        <div className="container">
          <div className="flex flex-wrap items-center">
            <div className="order-2 w-full md:w-5/12 lg:w-1/2 md:mr-auto md:order-1 md:pl-16 pt-6">
              <div className="ml-auto mr-auto lg:max-w-xl md:ml-0 md:mr-8">
                <Img fluid={page.contentSupportingImage.fluid} />
              </div>
            </div>

            <div className="order-1 w-full mb-6 md:w-1/2 lg:w-1/2 md:mb-0 md:order-2 md:pr-12 lg:pr-24">
              <div className="content" dangerouslySetInnerHTML={{ __html: page.content }}></div>
              <h4>Want to join the team?</h4>
              
              <Link className="inline-block text-base btn btn--outline text-secondary-dark" to="/careers">View Our Jobs</Link>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-12 overflow-hidden lg:mb-24">
        <div className="container">
          <div className="flex flex-wrap items-center md:-mx-1">
            {page.teamImages.map(({ fluid }, index) => {
              return(
                <div key={index} className="w-full mb-8 md:w-1/3 md:px-1">
                  <Img
                    className="w-full h-auto"
                    backgroundColor={`#3B5CC4`}
                    fluid={fluid}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default WhoWeArePage

export const query = graphql`
  query whoWeArePage {
    whoWeAre2: file(relativePath: { eq: "whoWeAre2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whoWeAre3: file(relativePath: { eq: "whoWeAre3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whoWeAre4: file(relativePath: { eq: "whoWeAre4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whoWeAre5: file(relativePath: { eq: "whoWeAre5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whoWeAre6: file(relativePath: { eq: "whoWeAre6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whoWeAre7: file(relativePath: { eq: "whoWeAre7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    page: datoCmsWhoWeAre {
      title
      introduction
      introductionSupportingImage {
        fluid(imgixParams: {h: "692", w: "500"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      stats {
        heading
        text
      }
      content
      contentSupportingImage {
        fluid(imgixParams: {h: "800", w: "800"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      usps {
        heading
        blurb
      }
      teamImages {
        fluid(
          maxWidth: 900
          imgixParams: {h: "900", w: "900", fit: "crop", crop: "center"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      metaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }
      slug
    }
  }
`